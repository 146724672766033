import { Dialog, Stack, styled } from '@rossum/ui/material';
import { useCallback, useEffect, useState } from 'react';
import { QuickSearchAutocomplete } from './QuickSearchAutocomplete';

const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
}));

export const QuickSearch = () => {
  const [open, setOpen] = useState(false);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (
      event.key === 'k' &&
      (event.metaKey === true || event.ctrlKey === true)
    ) {
      setOpen(open => !open);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      onKeyDown={e => e.stopPropagation()}
    >
      <Stack p={0.5}>
        {open ? (
          <QuickSearchAutocomplete onClose={() => setOpen(false)} />
        ) : null}
      </Stack>
    </StyledDialog>
  );
};
